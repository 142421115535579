import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import ParallaxHeader from "../components/ParallaxHeader";
import CompanyProfileBox from "./BusinessFiels/CompanyProfileBox";

const BusinessArea = () => {
  const [itzone, SetData] = useState([
    "МТ-ийн энтерпрайз тоног төхөөрөмж",
    "Кибер аюулгүй байдал",
    "Биет аюулгүй байдал",
    "МТ-ийн жижиглэн худалдаа",
  ]);

  const [novelsoft, SetData2] = useState([
    "Захиалгат програм хангамжийн хөгжүүлэлт",
    "Дата аналитик BI, AI & ML",
    "Дата менежмент",
    "Мэргэжлийн үйлчилгээ, аутсорсинг",
  ]);

  const [mss, SetData3] = useState([
    "МТ-ийн  дэд бүтцийн үйлчилгээ",
    "Канон Менежед Принтинг үйлчилгээ",
    "Клауд дэд бүтцийн үйлчилгээ",
    "Могул Дата Центр",
  ]);

  const [digitalworks, SetData4] = useState([
    "ERP систем хөгжүүлэлт",
    "Банкны Core system хөгжүүлэлт",
    "Бизнес процесс автоматжуулалт",
  ]);

  const [digitalpower, SetData5] = useState([
    "Үйлдвэрлэл, барилгын автоматжуулалт",
    "Цахилгаан инженерчлэл",
    "Консалтинг",
  ]);

  const [mex, SetData6] = useState([
    "Агуулахын үйлчилгээ",
    "Хүргэлтийн үйлчилгээ",
    "Гаалийн үйлчилгээ",
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {/* <PageHeaderMogul title="Салбар компаниуд" /> */}
      <ParallaxHeader
        title="Бизнесийн салбар"
        image="https://itzone.mn/photos/shares/mogul-web/window1s.jpg"
      />
      <div style={{ backgroundColor: "#f6f6f6" }}>
        <div
          className="centering-wrapper"
          style={{ paddingTop: "50px", paddingBottom: "100px" }}
        >
          <Row gutter={[36, 36]}>
            <Col
              xl={{ span: 8 }} //breakpoint 1200px
              lg={{ span: 8 }} //breakpoint 992px
              md={{ span: 12 }} //breakpoint 768px
              xs={{ span: 24 }} //breakpoint 480px
            >
              <CompanyProfileBox
                company="Ай Ти Зон ХХК"
                slogan="Таны дижитал аяллын итгэлт түнш"
                list={itzone}
                imgurl="https://itzone.mn/photos/shares/mogul-web/itzone-wallscreen.jpg"
                link="https://www.itzone.mn"
              />
            </Col>

            <Col
              xl={{ span: 8 }} //breakpoint 1200px
              lg={{ span: 8 }} //breakpoint 992px
              md={{ span: 12 }} //breakpoint 768px
              xs={{ span: 24 }} //breakpoint 480px
            >
              <CompanyProfileBox
                company="Новелсофт ХХК"
                slogan="Дижитал соёлыг түгээнэ"
                list={novelsoft}
                imgurl="https://itzone.mn/photos/shares/mogul-web/novelsoft_margulan.jpg"
                link="https://www.facebook.com/Novelsoftllc/"
              />
            </Col>

            <Col
              xl={{ span: 8 }} //breakpoint 1200px
              lg={{ span: 8 }} //breakpoint 992px
              md={{ span: 12 }} //breakpoint 768px
              xs={{ span: 24 }} //breakpoint 480px
            >
              <CompanyProfileBox
                company="Могул Сервис энд Саппорт ХХК"
                slogan="Бизнестээ анхаарах эрх чөлөөг олгоно"
                list={mss}
                imgurl="https://itzone.mn/photos/shares/mogul-web/mss_copier.jpg"
                link="https://www.mogulservice.mn"
              />
            </Col>

            <Col
              xl={{ span: 8 }} //breakpoint 1200px
              lg={{ span: 8 }} //breakpoint 992px
              md={{ span: 12 }} //breakpoint 768px
              xs={{ span: 24 }} //breakpoint 480px
            >
              <CompanyProfileBox
                company="Дижитал Воркс ХХК"
                slogan="Таны амжилтын хурдасгуур"
                list={digitalworks}
                link="https://www.facebook.com/digitalworks.mn"
              />
            </Col>

            <Col
              xl={{ span: 8 }} //breakpoint 1200px
              lg={{ span: 8 }} //breakpoint 992px
              md={{ span: 12 }} //breakpoint 768px
              xs={{ span: 24 }} //breakpoint 480px
            >
              <CompanyProfileBox
                company="Дижитал Повер ХХК"
                slogan="Консалтинг, Инженерчлэл, Үйлчилгээ"
                list={digitalpower}
                imgurl="https://itzone.mn/photos/shares/mogul-web/digitalpower.jpg"
                link="http://digitalpower.mn/about-us"
              />
            </Col>
            <Col
              xl={{ span: 8 }} //breakpoint 1200px
              lg={{ span: 8 }} //breakpoint 992px
              md={{ span: 12 }} //breakpoint 768px
              xs={{ span: 24 }} //breakpoint 480px
            >
              <CompanyProfileBox
                company="Могул Экспресс ХХК"
                slogan="Агуулах, Гааль, Түгээлт"
                list={mex}
                imgurl="https://itzone.mn/photos/shares/mogul-web/mex_warehouse.jpg"
                link="http://mex.mn/"
              />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default BusinessArea;

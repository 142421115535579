import { Button, Drawer, Row, Col } from "antd";
import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../Navbar/logo.svg";
import { MenuOutlined } from "@ant-design/icons";

const Navbar = () => {
  const [state, setstate] = useState(false);
  const changeClass = () => {
    const scrollValue = document.documentElement.scrollTop;
    if (scrollValue > 80) {
      setstate(true);
    } else {
      setstate(false);
    }
  };

  window.addEventListener("scroll", changeClass);

  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <div>
      <header
        className={state ? "mogul-header-dark mogul-header" : "mogul-header"}
      >
        <div className="centering-wrapper">
          <a href="/">
            <img src={logo} className="logo" alt="mogul" />
          </a>
          <nav className="nav-links">
            <NavLink
              to="aboutus"
              className={({ isActive }) => (isActive ? "active" : "inactive")}
            >
              Бидний тухай
            </NavLink>
            <NavLink
              to="businessarea"
              className={({ isActive }) => (isActive ? "active" : "inactive")}
            >
              Салбар компаниуд
            </NavLink>
            <NavLink
              to="media"
              className={({ isActive }) => (isActive ? "active" : "inactive")}
            >
              Медиа
            </NavLink>
            <NavLink
              to="career"
              className={({ isActive }) => (isActive ? "active" : "inactive")}
            >
              Карьер
            </NavLink>
          </nav>

          <div className="nav-buttons">
            <Link to="/contact" id="cta-btn">
              <Button type="primary" style={{ marginRight: "-22px" }}>
                Холбоо барих
              </Button>
            </Link>

            <div className="burger-menu">
              <Button
                type="primary"
                shape="dashed"
                icon={<MenuOutlined />}
                onClick={() => setDrawerOpen(true)}
              />
            </div>
          </div>
        </div>
      </header>
      <Drawer
        placement="top"
        onClose={handleDrawerClose}
        open={drawerOpen}
        closable={true}
        height={"100%"}
      >
        <Row>
          <Col className="drawer-nav-link">
            <NavLink
              to="aboutus"
              onClick={handleDrawerClose}
              className={"drawer-nav-link-item"}
            >
              Бидний тухай
            </NavLink>
            <NavLink
              to="businessarea"
              onClick={handleDrawerClose}
              className={"drawer-nav-link-item"}
            >
              Салбар компаниуд
            </NavLink>
            <NavLink
              to="media"
              onClick={handleDrawerClose}
              className={"drawer-nav-link-item"}
            >
              Медиа
            </NavLink>
            <NavLink
              to="career"
              onClick={handleDrawerClose}
              className={"drawer-nav-link-item"}
            >
              Карьер
            </NavLink>
            <NavLink
              to="contact"
              onClick={handleDrawerClose}
              className={"drawer-nav-link-item"}
            >
              Холбоо барих
            </NavLink>
          </Col>
        </Row>
      </Drawer>
    </div>
  );
};

export default Navbar;

import React, { useEffect, useState } from "react";
import {
  Col,
  Input,
  Row,
  Form,
  Button,
  Divider,
  Select,
  DatePicker,
  Avatar,
  Upload,
  message,
  Spin,
} from "antd";
import { UserOutlined, UploadOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import axios from "axios";

import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { doc, getDoc } from "firebase/firestore";
import { db, storage } from "../firebase/firebase-config";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import logoblack from "../logo.svg";

const { RangePicker } = DatePicker;

const { Option } = Select;

const CareerForm = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const [avatarUrl, setAvatarUrl] = useState();
  const [cvUrl, setCvUrl] = useState();

  const [loading, setLoading] = useState();

  const EDUCATION = {
    1: "Бага",
    2: "Бүрэн дунд",
    3: "Дээд",
    4: "Доктор",
  };

  const getData = async () => {
    const CareerDoc = doc(db, "careers", params?.id);
    const response = await getDoc(CareerDoc);

    const tData = response.data();

    form.setFieldsValue({
      position: tData.jobTitle,
      company: tData.company,
    });
  };

  const onSent = (values) => {
    setLoading(true);
    values.avatar_url = avatarUrl;

    let empHistory = "";
    if (values.company1) {
      empHistory += `<li>${values.company1}: ${values.position1}, ${moment(
        values.date1[0]
      ).format("YYYY")}-${moment(values.date1[1]).format("YYYY")}</li>`;
    }
    if (values.company2) {
      empHistory += `<li>${values.company2}: ${values.position2}, ${moment(
        values.date2[0]
      ).format("YYYY")}-${moment(values.date2[1]).format("YYYY")} </li>`;
    }
    if (values.company3) {
      empHistory += `<li>${values.company3}: ${values.position3}, ${moment(
        values.date3[0]
      ).format("YYYY")}-${moment(values.date3[1]).format("YYYY")} </li>`;
    }

    axios
      .post("https://api.emailjs.com/api/v1.0/email/send", {
        service_id: "service_d5kuj4l",
        template_id: "template_dpym1jf",
        user_id: "QH1OAXsFu6dLSZtNy",
        template_params: {
          name: values.lastname + " " + values.firstname,
          birthdate: moment(values.birthdate).format("YYYY.MM.DD"),
          sex: values.sex == "male" ? "Эрэгтэй" : "Эмэгтэй",
          education: EDUCATION[values.education],
          profession: values.profession,
          email: values.email,
          social: values.social,
          phonenumber: values.phonenumber,
          interest: values.company + "/" + values.position,
          emp_history: empHistory,
          cv_url: cvUrl,
        },
      })
      .then(function (response) {
        message.success("Амжилттай илгээгдлээ");
        navigate(-1);
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        message.error("Амжилтгүй");
        console.log(error);
      });
  };

  const onFailed = (errorInfo) => {
    setLoading(false);
    // message.error("Амжилтгүй");
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    getData();
  }, []);

  const uploadAvatar = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    const imageRef = ref(storage, `cv_avatars/${file.name}`);
    try {
      let response = uploadBytesResumable(imageRef, file);

      response.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          onProgress({ percent: progress });
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
          }
        },
        (error) => {
          console.error(error);
        },
        async () => {
          await getDownloadURL(response.snapshot.ref).then((downloadURL) => {
            setAvatarUrl(downloadURL);
            onSuccess("Ok");
            return downloadURL;
          });
        }
      );

      return;
    } catch (error) {
      onError({ error });
    }
  };

  const uploadCV = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    const fileRef = ref(storage, `cv_avatars/${file.name}`);
    try {
      let response = uploadBytesResumable(fileRef, file);

      response.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          onProgress({ percent: progress });
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
          }
        },
        (error) => {
          console.error(error);
        },
        async () => {
          await getDownloadURL(response.snapshot.ref).then((downloadURL) => {
            setCvUrl(downloadURL);
            onSuccess("Ok");
            return downloadURL;
          });
        }
      );

      return;
    } catch (error) {
      onError({ error });
    }
  };
  return (
    <div className="career-form-wrapper">
      <div className="centering-wrapper custom-wrapper">
        <div className="career-form">
          <div
            style={{
              borderBottom: "1px solid #000",
              marginBottom: "50px",
            }}
          >
            <img src={logoblack} className="logo-career-form" alt="mogul" />
          </div>

          <Form
            form={form}
            layout="vertical"
            onFinish={onSent}
            onFinishFailed={onFailed}
          >
            <Divider>Ерөнхий мэдээлэл</Divider>

            <Row gutter={[16, 16]}>
              <Col
                xl={{ span: 12 }} //breakpoint 1200px
                lg={{ span: 12 }} //breakpoint 992px
                md={{ span: 24 }} //breakpoint 768px
                xs={{ span: 24 }} //breakpoint 480px
              >
                <Row gutter={[16, 16]}>
                  <Col
                    xl={{ span: 12 }} //breakpoint 1200px
                    lg={{ span: 12 }} //breakpoint 992px
                    md={{ span: 24 }} //breakpoint 768px
                    xs={{ span: 24 }} //breakpoint 480px
                  >
                    <Form.Item
                      name="lastname"
                      label="Овог"
                      rules={[
                        { required: true, message: "Овог заавал бичнэ үү." },
                      ]}
                    >
                      <Input placeholder="Овог бичнэ үү" />
                    </Form.Item>
                  </Col>
                  <Col
                    xl={{ span: 12 }} //breakpoint 1200px
                    lg={{ span: 12 }} //breakpoint 992px
                    md={{ span: 24 }} //breakpoint 768px
                    xs={{ span: 24 }} //breakpoint 480px
                  >
                    <Form.Item
                      name="firstname"
                      label="Нэр"
                      rules={[
                        { required: true, message: "Нэр заавал бичнэ үү." },
                      ]}
                    >
                      <Input placeholder="Нэр бичнэ үү" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col
                    xl={{ span: 12 }} //breakpoint 1200px
                    lg={{ span: 12 }} //breakpoint 992px
                    md={{ span: 24 }} //breakpoint 768px
                    xs={{ span: 24 }} //breakpoint 480px
                  >
                    <Form.Item
                      name="birthdate"
                      label="Төрсөн он сар"
                      rules={[
                        {
                          required: true,
                          message: "Төрсөн он сар заавал оруулана үү.",
                        },
                      ]}
                    >
                      <DatePicker
                        style={{ width: "100%" }}
                        placeholder="Огноо сонгоно уу"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xl={{ span: 12 }} //breakpoint 1200px
                    lg={{ span: 12 }} //breakpoint 992px
                    md={{ span: 24 }} //breakpoint 768px
                    xs={{ span: 24 }} //breakpoint 480px
                  >
                    <Form.Item
                      name="sex"
                      label="Хүйс"
                      rules={[
                        { required: true, message: "Хүйс заавал сонгоно уу." },
                      ]}
                    >
                      <Select
                        style={{ width: "100%" }}
                        placeholder="Хүйс сонгоно уу"
                      >
                        <Option value="male">Эр</Option>
                        <Option value="female">Эм</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col
                    xl={{ span: 12 }} //breakpoint 1200px
                    lg={{ span: 12 }} //breakpoint 992px
                    md={{ span: 24 }} //breakpoint 768px
                    xs={{ span: 24 }} //breakpoint 480px
                  >
                    <Form.Item
                      name="education"
                      label="Боловсрол"
                      rules={[
                        {
                          required: true,
                          message: "Боловсрол заавал сонгоно уу.",
                        },
                      ]}
                    >
                      <Select
                        style={{ width: "100%" }}
                        placeholder="Боловсрол сонгоно уу"
                      >
                        <Option value="1">Бага</Option>
                        <Option value="2">Бүрэн дунд</Option>
                        <Option value="3">Дээд</Option>
                        <Option value="4">Доктор</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col
                    xl={{ span: 12 }} //breakpoint 1200px
                    lg={{ span: 12 }} //breakpoint 992px
                    md={{ span: 24 }} //breakpoint 768px
                    xs={{ span: 24 }} //breakpoint 480px
                  >
                    <Form.Item
                      name="profession"
                      label="Мэргэжил"
                      rules={[
                        {
                          required: true,
                          message: "Мэргэжил заавал бичнэ үү.",
                        },
                      ]}
                    >
                      <Input placeholder="Мэргэжил бичнэ үү" />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col
                xl={{ span: 8 }} //breakpoint 1200px
                lg={{ span: 8 }} //breakpoint 992px
                md={{ span: 12 }} //breakpoint 768px
                xs={{ span: 24 }} //breakpoint 480px
              >
                <div className="avatar-upload">
                  <Form.Item name="avatar_url">
                    <Upload
                      listType="picture-card"
                      showUploadList={false}
                      maxCount={1}
                      customRequest={uploadAvatar}
                    >
                      <Avatar
                        shape="square"
                        size={200}
                        icon={<UserOutlined />}
                        src={avatarUrl}
                      />
                    </Upload>
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <Divider>Сонирхож буй албан тушаал</Divider>
            <Row gutter={[16, 16]}>
              <Col
                xl={{ span: 8 }} //breakpoint 1200px
                lg={{ span: 8 }} //breakpoint 992px
                md={{ span: 12 }} //breakpoint 768px
                xs={{ span: 24 }} //breakpoint 480px
              >
                <Form.Item name="company" required label="Компани">
                  <Input disabled />
                  {/* <Select style={{ width: "100%" }} placeholder="Компани сонгох" disabled>
                                        <Option value="1">ITZONE LLC</Option>
                                        <Option value="2">NovelSoft LLC</Option>
                                        <Option value="3">Mogul Service and Support LLC</Option>
                                        <Option value="4">Digital Power LLC</Option>
                                        <Option value="5">Mogul Service and Support</Option>
                                        <Option value="6">Digital Works LLC</Option>
                                    </Select> */}
                </Form.Item>
              </Col>

              <Col
                xl={{ span: 9 }} //breakpoint 1200px
                lg={{ span: 9 }} //breakpoint 992px
                md={{ span: 12 }} //breakpoint 768px
                xs={{ span: 24 }} //breakpoint 480px
              >
                <Form.Item name="position" required label="Ажлын байр">
                  <Input disabled />
                  {/* <Select style={{ width: "100%" }} placeholder="Ажлын байр сонгох" disabled>
                                        <Option value="123">Ашиглалтын инженер</Option>
                                        <Option value="321">Кибер аюулгүй байдлын мэргэжилтэн</Option>
                                        <Option value="4566">Талбайн хяналтын инженер</Option>
                                    </Select> */}
                </Form.Item>
              </Col>
              <Col
                xl={{ span: 7 }} //breakpoint 1200px
                lg={{ span: 7 }} //breakpoint 992px
                md={{ span: 12 }} //breakpoint 768px
                xs={{ span: 24 }} //breakpoint 480px
              >
                <Form.Item name="cv" label="CV хавсаргах">
                  <Upload
                    listType="text"
                    maxCount={1}
                    showUploadList={true}
                    customRequest={uploadCV}
                  >
                    <Button
                      icon={<UploadOutlined />}
                      style={{
                        width: "100%",
                      }}
                    >
                      Upload (Max: 1)
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>
            </Row>

            <Divider>Ажлын туршлага</Divider>
            <Row gutter={[16, 8]}>
              <Col
                xl={{ span: 8 }} //breakpoint 1200px
                lg={{ span: 8 }} //breakpoint 992px
                md={{ span: 12 }} //breakpoint 768px
                xs={{ span: 24 }} //breakpoint 480px
              >
                <Form.Item name="company1">
                  <Input placeholder="Компани" />
                </Form.Item>
              </Col>

              <Col
                xl={{ span: 9 }} //breakpoint 1200px
                lg={{ span: 9 }} //breakpoint 992px
                md={{ span: 12 }} //breakpoint 768px
                xs={{ span: 24 }} //breakpoint 480px
              >
                <Form.Item name="position1">
                  <Input placeholder="Албан тушаал" />
                </Form.Item>
              </Col>

              <Col
                xl={{ span: 7 }} //breakpoint 1200px
                lg={{ span: 7 }} //breakpoint 992px
                md={{ span: 12 }} //breakpoint 768px
                xs={{ span: 24 }} //breakpoint 480px
              >
                <Form.Item name="date1">
                  <RangePicker style={{ width: "100%" }} picker="year" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[16, 8]}>
              <Col
                xl={{ span: 8 }} //breakpoint 1200px
                lg={{ span: 8 }} //breakpoint 992px
                md={{ span: 12 }} //breakpoint 768px
                xs={{ span: 24 }} //breakpoint 480px
              >
                <Form.Item name="company2">
                  <Input placeholder="Компани" />
                </Form.Item>
              </Col>

              <Col
                xl={{ span: 9 }} //breakpoint 1200px
                lg={{ span: 9 }} //breakpoint 992px
                md={{ span: 12 }} //breakpoint 768px
                xs={{ span: 24 }} //breakpoint 480px
              >
                <Form.Item name="position2">
                  <Input placeholder="Албан тушаал" />
                </Form.Item>
              </Col>

              <Col
                xl={{ span: 7 }} //breakpoint 1200px
                lg={{ span: 7 }} //breakpoint 992px
                md={{ span: 12 }} //breakpoint 768px
                xs={{ span: 24 }} //breakpoint 480px
              >
                <Form.Item name="date2">
                  <RangePicker style={{ width: "100%" }} picker="year" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col
                xl={{ span: 8 }} //breakpoint 1200px
                lg={{ span: 8 }} //breakpoint 992px
                md={{ span: 12 }} //breakpoint 768px
                xs={{ span: 24 }} //breakpoint 480px
              >
                <Form.Item name="company3">
                  <Input placeholder="Компани" />
                </Form.Item>
              </Col>

              <Col
                xl={{ span: 9 }} //breakpoint 1200px
                lg={{ span: 9 }} //breakpoint 992px
                md={{ span: 12 }} //breakpoint 768px
                xs={{ span: 24 }} //breakpoint 480px
              >
                <Form.Item name="position3">
                  <Input placeholder="Албан тушаал" />
                </Form.Item>
              </Col>

              <Col
                xl={{ span: 7 }} //breakpoint 1200px
                lg={{ span: 7 }} //breakpoint 992px
                md={{ span: 12 }} //breakpoint 768px
                xs={{ span: 24 }} //breakpoint 480px
              >
                <Form.Item name="date3">
                  <RangePicker style={{ width: "100%" }} picker="year" />
                </Form.Item>
              </Col>
            </Row>

            <Divider>Холбоо барих</Divider>

            <Row gutter={[16, 16]}>
              <Col
                xl={{ span: 8 }} //breakpoint 1200px
                lg={{ span: 8 }} //breakpoint 992px
                md={{ span: 12 }} //breakpoint 768px
                xs={{ span: 24 }} //breakpoint 480px
              >
                <Form.Item
                  name="phonenumber"
                  rules={[
                    {
                      required: true,
                      message: "Утасны дугаар заавал бичнэ үү.",
                    },
                  ]}
                >
                  <Input placeholder="Утасны дугаар" />
                </Form.Item>
              </Col>

              <Col
                xl={{ span: 9 }} //breakpoint 1200px
                lg={{ span: 9 }} //breakpoint 992px
                md={{ span: 12 }} //breakpoint 768px
                xs={{ span: 24 }} //breakpoint 480px
              >
                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: "Имэйл заавал бичнэ үү." },
                  ]}
                >
                  <Input placeholder="И-мэйл хаяг" />
                </Form.Item>
              </Col>

              <Col
                xl={{ span: 7 }} //breakpoint 1200px
                lg={{ span: 7 }} //breakpoint 992px
                md={{ span: 12 }} //breakpoint 768px
                xs={{ span: 24 }} //breakpoint 480px
              >
                <Form.Item name="social">
                  <Input placeholder="Сошиал" />
                </Form.Item>
              </Col>
            </Row>

            {loading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Spin />
              </div>
            ) : (
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  size="large"
                  style={{ marginTop: "50px" }}
                >
                  Анкет илгээх
                </Button>
              </Form.Item>
            )}
          </Form>
        </div>
      </div>
    </div>
  );
};

export default CareerForm;

import React from "react";
import { Button } from "antd";
import { Parallax } from "react-parallax";
import { Link } from "react-router-dom";

const CareerCover = () => {
  return (
    <Parallax
      bgImage={
        "https://prodcmscdn.azureedge.net/careerconnectresources/p/MICRUS/images/Hero%20image_new-1556706557568.png"
      }
      strength={130}
    >
      <div className="careerHeader">
        <div className="centering-wrapper">
          <h1>Дижитал аялалд хамтдаа</h1>
          <Link to="/career/open-positions">
            <Button
              size="large"
              style={{
                background: "#0444CC",
                color: "#fff",
                borderColor: "#0444CC",
                margin: "0 10px 0 0",
              }}
            >
              Нээлттэй ажлын байр
            </Button>
          </Link>

          <Link to="/career/student">
            <Button
              size="large"
              style={{
                background: "none",
                color: "#fff",
                borderColor: "#0444CC",
                margin: "30px 10px",
                size: "large",
              }}
            >
              Оюутны дадлага
            </Button>
          </Link>
        </div>
      </div>
    </Parallax>
  );
};

export default CareerCover;

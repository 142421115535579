import {
  PhoneOutlined,
  MailOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons/lib/icons";
import { Col, Row } from "antd";

import React, { useEffect } from "react";
import SalesForceForm from "../components/Contact/SalesForceForm";
 import ParallaxHeader from "../components/ParallaxHeader";

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <ParallaxHeader
        title="Холбоо барих"
        image=""
      />

      <div className="centering-wrapper">
        <Row style={{ padding: "50px 0 100px" }}>
          <Col md={{ span: 12 }} sm={{ span: 24 }}>
            <div className="contactData">
              <Row>
                <Col
                  md={{ span: 6 }}
                  style={{
                    display: "flex",
                    alignItems: "top",
                  }}
                >
                  <EnvironmentOutlined
                    style={{
                      fontSize: 23,
                      color: "#fff",
                      marginTop: "15px",
                      marginBottom: "0px",
                    }}
                  />
                </Col>

                <Col md={{ span: 18 }}>
                  <p>
                    Монгол улс, Улаанбаатар 17010-0057, Хан-Уул дүүрэг, Стадион
                    Оргил-1, Чингисийн өргөн чөлөө-6, Хаан банк цамхаг, 22
                    давхар Могул групп
                  </p>
                </Col>
              </Row>

              <Row>
                <Col
                  md={{ span: 6 }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <PhoneOutlined style={{ fontSize: 23, color: "#fff" }} />
                </Col>

                <Col md={{ span: 18 }}>
                  <p>+(976) 7731-7731</p>
                </Col>
              </Row>

              <Row>
                <Col
                  md={{ span: 6 }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <MailOutlined style={{ fontSize: 23, color: "#fff" }} />
                </Col>

                <Col md={{ span: 18 }}>
                  <p>support@mogul.mn</p>
                </Col>
              </Row>
            </div>
          </Col>

          <Col md={{ span: 12 }} sm={{ span: 24 }}>
            <div className="contacForm">
              <SalesForceForm />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Contact;
